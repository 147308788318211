<template>
  <v-app>
    <v-container fluid grid-list-md class="outerContainer" :class="`elevation-3`">
      <div class="pageHeading"> <v-btn @click="close()" icon> <v-icon dense> mdi-arrow-left </v-icon> </v-btn> {{ Heading }} </div>
      <v-divider></v-divider>
      <v-spacer></v-spacer>
      <v-container>
        <div class="text-info font-weight-black"> Trigger Details </div>
        <v-row>
          <v-col md="3"> <label class="c-input-label" >Trigger Name</label> <div class="inputAlignment font-weight-black"> {{ functionName }} </div> </v-col>
          <v-col md="2" class="switchToggle"> <Toggle v-model="active" :input.sync="active" :useSubmitMethod="false" /> </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <label class="c-input-label">Trigger Description</label>
            <Input v-model="eventName"/>
          </v-col>
          <v-col md="4">
            <label class="c-input-label">Trigger Type</label>
            <Select name="simpleSelect" label="Trigger Type" v-model="defaultEventTime" :allOptions="typeOfTrigger" />
          </v-col>
          <v-col md="4" v-if="getTriggerType(defaultEventTime)">
            <label class="c-input-label">Select Trigger Time</label>
            <div class="input-group"> <input class="c-input" type="time" v-model="time" /> </div>
          </v-col>
        </v-row>
        <div class="text-info font-weight-black" v-if="relatedTemplates.length !== 0"> Languages </div>
        <v-layout row wrap>
          <v-flex v-for="template in relatedTemplates" :key="template.templateId" xs12 md6 lg3>
            <v-card class="mx-auto" flat min-width="231px" max-width="231px" :key="template.templateId">
              <Input v-model="template.language" isDisabled/>
              <TextArea :localValue="template.content" isDisabled :labelDisplay="false" placeholderHeight="170px" placeholderWidth="231px" />
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-container>
    <v-overlay :value="openDialog">
      <Modal :showConfirmationDialog.sync="openDialog" :item="TriggerDetails" :submitFunction="this.submit" :confirmationDialogText="confirmationDialogText"/>
    </v-overlay>
    <v-container class="widthContainer">
      <div>
        <Button @click="close()" type="outlined" label="Cancel" /> &nbsp;
        <Button @click="submitDetails()" label="Save" />
      </div>
    </v-container>
  </v-app>
</template>
<script>

import { mapActions } from 'vuex'
import Modal from '../sharedcomponents/Modal.vue'
import Button from '../sharedcomponents/Button.vue'
import Toggle from '../sharedcomponents/Switch.vue'
import Input from '../sharedcomponents/Input.vue'
import Select from '../sharedcomponents/Select.vue'
import TextArea from '../sharedcomponents/TextArea.vue'
import { changeCronToTimeString } from '../../../utils/utils.js'

export default {
  props: ['Heading', 'submit', 'TriggerDetails'],
  components: { Modal, Toggle, Input, Select, TextArea, Button },
  data () {
    return {
      time: null,
      typeOfTrigger: [
        { Key: 'Event', Value: 'Event' },
        { Key: 'Time', Value: 'Time' }
      ],
      defaultEventTime: '0:0',
      functionName: '',
      eventName: '',
      relatedTemplates: '',
      openDialog: false,
      active: false,
      confirmationDialogText: {
        title: '',
        dialogtext: '',
        dialogCancelText: 'Cancel',
        dialogSubmitText: 'Continue'
      }
    }
  },
  beforeMount () {
    this.active = this.TriggerDetails.isActive
    this.time = this.changeCronToTime(this.TriggerDetails.cronTime)
    this.defaultEventTime = this.getEventTimeType(this.time)
    this.functionName = this.TriggerDetails.functionName
    this.eventName = this.TriggerDetails.eventName
    this.relatedTemplates = this.TriggerDetails.relatedTemplates
    this.setDeploymentName(this.$route.query.platform)
  },
  methods: {
    ...mapActions([
      'setDeploymentName'
    ]),
    getEventTimeType (cronTime) {
      if (cronTime === '00:00') {
        return { Key: 'Event', Value: 'Event' }
      } else {
        return { Key: 'Time', Value: 'Time' }
      }
    },
    switch () {
      this.active = !this.active
    },
    close () {
      this.$router.push({ name: 'triggerManagement', query: { platform: this.$route.query.platform } })
    },
    submitDetails () {
      this.TriggerDetails.isActive = this.active
      const dateToCron = this.time.split(":")
      this.TriggerDetails.cronTime = dateToCron[1] + " " + dateToCron[0] + " * * *"
      this.TriggerDetails.eventName = this.eventName
      this.confirmationDialogText.title = "Save Changes"
      this.confirmationDialogText.dialogtext = 'Are you sure you want to save changes to ' + this.TriggerDetails.eventName + ' Trigger?'
      this.openDialog = true
    },
    getTriggerType (item) {
      if (item.Value === 'Time') return true
      return false
    },
    changeCronToTime (cronTime) {
      if (cronTime === null || cronTime === '') return '00:00'
      var date = changeCronToTimeString(cronTime)
      var output = ''
      if (date.getHours() < 10) {
        output = '0' + date.getHours().toString() + ':'
      } else {
        output = date.getHours().toString() + ':'
      }
      if (date.getMinutes() === 0) output += '00'
      else output += date.getMinutes().toString()
      return output
    }
  }
}
</script>

<style scoped>
.pageHeading {
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.005em;
  color: #1E234C;
  text-align: left;
}
.widthContainer {
  width: 1064px !important;
}
.widthContainer div {
  float: right;
  margin-top: 10px;
}
.widthContainer div Button{
  height: 40px;
  padding: 0px 16px !important;
}
.outerContainer {
  width: 1064px !important;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-family: IBM Plex Sans;
  font-style: normal;
}
.switchToggle {
  max-width: 75px;
    align-self: self-end;
    padding-bottom: 5px;
    padding-left: 0px;
}
.inputAlignment {
  text-align: left;
}

.c-input-label{
  margin-right: 32px;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: 0.0125em;
  float: left;
}

.c-input {
  padding: 11px 16px;
  background: #FFFFFF;
  border: none;
  height: 50px;
  border: 1px solid #9CA5C2;
  line-height: 26px;
  border-radius: 8px;
  color: #1E234C !important;
  flex: 1;
  min-width: 0;
  width: 100%;
  font-weight: bold;
}

.input-group {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: left !important;
  margin: 20px 0;
  align-self: end;
  width: 100%;
}

.input {
  padding: 11px 16px;
  border: none;
  line-height: 26px;
  border-radius: 8px;
  color: #1E234C;
  background-size: 16px 16px;
  width: 300px;
  cursor: default;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #9CA5C2;
  font-family: 'IBM Plex Sans', sans-serif;
}
.text-info {
  text-align: left;
  padding: 16px 0px 20px 0px;
  color: #1155CC;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
}
.c-input::placeholder {
  color: #A0A3BD;
}
.c-input:hover {
  border : none;
  border: 1px solid #4200FF;
}
.c-input:focus {
  border: none;
  outline: none;
  border: 1px solid #1155CC;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.12);
}
Select {
  font-weight: bold;
}

</style>
