<template>
  <button
    :class="['c-button', 'text-ibm-plex-sans', color, type]"
    :disabled="disabled"
    @click="onClick">
    {{ label }}
  </button>
</template>

// available type = solid/outlined/link/text

<script>
export default {
  name: 'Button',
  props: {
    label: {
      type: String,
      default: 'Button'
    },
    type: {
      type: String,
      default: 'solid'
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>

// common style

.c-button {
    // margin: 18px 20px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
}

// type specific styles

.solid {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.solid, .outlined {
    padding: 19px 24px;
    border: none;
    border-radius: 6px;
}

.outlined {
    border: 1px solid;
    box-sizing: border-box;
    background: transparent;
}

.link, .text {
    border: none;
    background: none;
}

.link {
    text-decoration: underline;
}

// hover

.solid:hover {
    box-shadow: none;
}

// active

.solid:active {
    box-shadow: inset 0px 1px 1px;
}

// background

.primary.solid {
  background-color: #1155CC;
}
.secondary.solid {
  background-color: #EF485C;
}
.white.solid {
  background-color: #FFFFFF;
}
.primary.outlined {
  background-color: #FFFFFF !important;
}

// text

.primary.solid {
  color: #FFFFFF;
}
.primary.outlined, .primary.link, .primary.text {
  color: #1155CC;
}
.secondary.solid {
  color: #FFFFFF;
}
.secondary.outlined, .secondary.link, .secondary.text  {
  color: #EF485C;
}
.white.solid {
  color: #1E234C;
}
.white.outlined, .white.link, .white.text {
  color: #FFFFFF;
}
.dark.text {
  color: #1E234C;
}

// hover

.secondary.solid:hover {
    background-color: #C92A3D;
}
.primary.solid:hover {
    background-color: #1155CC;
}
.primary.outlined:hover {
    background-color: #D0D7E2;
}
.secondary.outlined:hover {
    background-color: #E6D6D8;
}

// active

.secondary.solid:active {
    background-color: #C92A3D;
}
.primary.solid:active {
    background-color: #1155CC;
}
.primary.outlined:active {
    background-color: #D0D7E2;
}
.secondary.outlined:active {
    background-color: #E6D6D8;
}

// disabled

.solid:disabled, .outlined:disabled,
.solid:disabled:active, .outlined:disabled:active,
.solid:disabled:hover, .outlined:disabled:hover {
    background: #D6D8E7;
}

button:disabled {
    box-shadow: none;
    color: #A0A3BD !important;
}

button:disabled:hover {
    box-shadow: none;
}

button:disabled:active {
    box-shadow: none;
}

</style>
