<template>
  <div class="input-group">
    <div class="length">
      <label for="c-input" class="c-input-label" v-if="labelDisplay">{{ label }}</label>
      <div class="input-group-field">
        <span v-if="addOn" class="input-group-addon">{{ addOn }}</span>
        <input
          :name=name
          :class=getInputClass()
          :placeholder=placeholderText
          :label=label
          :disabled="isDisabled"
          @change="onDataChange"
          @keyup="onDataChange"
          @click="onDataChange"
          v-model="localValue">
      </div>
      <div v-if="hasError" class="error-container"><p class="error-message">{{ errorMessage }}</p></div>
      <div v-if="hasInfo && !hasError" class="text-info">{{ infoMessage }}</div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      localValue: '',
      isRequiredErrorMsg: 'error_field_required',
      minLengthErrorMsg: 'Minimum length should be '
    }
  },
  name: 'Input',
  props: {
    labelDisplay: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    name: {
      type: String
    },
    label: {
      type: String,
      default: 'Label'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    minLength: {
      type: Number
    },
    addOn: {
      type: String
    },
    hasInfo: {
      type: Boolean,
      default: false
    },
    infoMessage: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.localValue = this.value
  },
  computed: {
    hasError: {
      get: function () {
        if (this.isRequired && !this.localValue) {
          return true
        }
        if (this.minLength && this.localValue && this.localValue.length < this.minLength) {
          return true
        }
        return false
      },
      set: function (value) {
        return value
      }
    },
    errorMessage: {
      get: function () {
        let errorMessage = ''
        if (this.isRequired && !this.localValue) {
          errorMessage = this.isRequiredErrorMsg
        }
        if (this.minLength && this.localValue && this.localValue.length < this.minLength) {
          errorMessage = this.minLengthErrorMsg + this.minLength
        }
        return errorMessage
      },
      set: function (value) {
        return value
      }
    }
  },
  watch: {
    localValue () {
      if (!this.localValue && this.isRequired) {
        this.hasError = true
        this.errorMessage = this.isRequiredErrorMsg
      } else if (this.minLength && this.localValue && this.localValue.length < this.minLength) {
        this.hasError = true
        this.errorMessage = this.minLengthErrorMsg + this.minLength
      } else {
        this.hasError = false
      }
    },
    value (val) {
      this.localValue = val
    }
  },
  methods: {
    getInputClass () {
      return {
        'c-input': true,
        'text-ibm-plex-sans-16': true,
        'error-input': this.hasError,
        'addon-exists': this.addOn != null
      }
    },
    onDataChange () {
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
      this.$emit('click', this.localValue)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.input-group {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: left !important;
  margin: 20px 0;
}

.input-group-field {
  display: flex;
  width: 100%;
}

.c-input {
  padding: 11px 16px;
  background: #FFFFFF;
  border: none;
  border: 1px solid #9CA5C2;
  line-height: 26px;
  border-radius: 8px;
  color: #1E234C !important;
  flex: 1;
  min-width: 0;
  width: 100%;
}

.c-input.addon-exists {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.text-ibm-plex-sans-16{
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
}

.c-input::placeholder {
  color: #A0A3BD;
}

.c-input:hover {
  border : none;
  border: 1px solid #4200FF;
}

.c-input:focus {
  border: none;
  outline: none;
  border: 1px solid #1155CC;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.12);
}

.c-input-label{
  margin-right: 32px;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: 0.0125em;
  float: left;
}

.c-input:disabled {
  background: #EFF0F6;
  border: none;
}

.c-input:disabled + .c-input-label {
  color: #A0A3BD;
}

.error-container{
  margin-top: 2px;
}

.text-info{
  margin-top: 2px;
  color: #1E234C;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.error-message{
  margin: 0px;
  color: #F14848;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.error-input{
  border: 1px solid #F14848 !important;
  color: #F14848;
}

.input-group-addon {
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  background-color: #EFF0F6;
  border: none;
  border-radius: 8px;
  padding: 11px 8px;
  line-height: 26px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  display: inline-block;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #1E234C;
}

.length{
  width: 100%;
}

</style>
