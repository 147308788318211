<template>
  <div class="input-group">
    <div>
    <label for="c-input" class="c-input-label">
      <textarea
        :name=name
        :class=getInputClass()
        :placeholder=placeholderText
        :label=label
        :disabled="isDisabled"
        v-model="localValue"
        :style="{height: placeholderHeight, width: placeholderWidth}"></textarea>
        <span class="label" v-if="labelDisplay">
        {{ label }}
      </span>
        </label>
      <div v-if="hasError" class="error-container"><p class="error-message">{{ errorMessage }}</p></div>
      <div v-if="hasInfo && !hasError" class="text-info">{{ infoMessage }}</div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      isRequiredErrorMsg: 'error_field_required'
    }
  },
  name: 'Input',
  props: {
    labelDisplay: {
      type: Boolean,
      default: true
    },
    localValue: {
      type: String,
      default: ''
    },
    name: {
      type: String
    },
    label: {
      type: String,
      default: 'Label'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    placeholderWidth: {
      type: String,
      default: '500px'
    },
    placeholderHeight: {
      type: String,
      default: '100px'
    },
    hasInfo: {
      type: Boolean,
      default: false
    },
    infoMessage: {
      type: String,
      default: null
    }
  },
  computed: {
    hasError: {
      get: function () {
        if (this.isRequired && !this.localValue) {
          return true
        }
        return false
      },
      set: function (value) {
        return value
      }
    },
    errorMessage: {
      get: function () {
        let errorMessage = ''
        if (this.isRequired && !this.localValue) {
          errorMessage = this.isRequiredErrorMsg
        }
        return errorMessage
      },
      set: function (value) {
        return value
      }
    }
  },
  watch: {
    localValue () {
      if (!this.localValue && this.isRequired) {
        this.hasError = true
        this.errorMessage = this.isRequiredErrorMsg
      } else {
        this.hasError = false
      }
    }
  },
  methods: {
    getInputClass () {
      return {
        'c-input': true,
        'error-input': this.hasError
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

textarea {
  resize: none;
}

.input-group {
  display: flex;
  margin-bottom: 20px;
}

.c-input {
  padding: 11px 16px;
  background: #FFFFFF;
  border: 1px solid #9CA5C2;
  line-height: 26px;
  border-radius: 8px;
  color: #1E234C;
}

.c-input::placeholder {
  color: #A0A3BD;
}

.c-input:hover {
  border : none;
  border: 0.6px solid #0070D9;
}

.c-input:focus {
  border: none;
  outline: none;
  border: 1px solid #0070D9;
}

.c-input-label{
  display: flex;
  flex-direction: column-reverse;
  text-align: initial;
  // width: 300px;
}

.c-input:disabled {
  background: #EFF0F6;
  border: none;
}

.label{
    height: 22px;
}

.c-input:disabled + .c-input-label {
  color: #A0A3BD;
}

.error-container{
  margin-top: 2px;
}

.text-info{
  margin-top: 2px;
  color: #0070D9;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.error-message{
  margin: 0px;
  color: #F14848;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.error-input{
  border: 1px solid #F14848 !important;
  color: #F14848;
}

</style>
