<template>
  <EditTriggerPage :Heading = "getHeading()" :submit="this.submit()" :TriggerDetails="this.obj.Trigger"/>
</template>

<script>
import EditTriggerPage from '../components/TriggerManagement/TriggerDetails.vue'
import { initializePlatformMatomo } from '../../utils/matomoTracking'
import { routeNames } from '../../utils/dataValidations'
import router from './../router/index'
import { mapState, mapActions } from 'vuex'
const formatTriggerdata = require("../../utils/formatTriggerData")

export default {
  components: { EditTriggerPage },
  props: ['obj'],
  computed: {
    ...mapState([
      'isAuth',
      'isProd'
    ])
  },
  mounted: function () {
    initializePlatformMatomo()
    if (!this.isAuth) {
      router.push(routeNames.Home)
    }
    this.setDeploymentName(this.$route.query.platform)
    if (this.isProd === null){
      router.push(routeNames.Welcome)
    }
  },
  methods: {
    ...mapActions([
      'setDeploymentName',
      'updateTriggerPostCall',
      'cloneTriggerPostCall'
    ]),
    getHeading: function (){
      var heading = ' ' + this.obj.Type
      if (this.obj.Trigger.defaultTemplateId === 0 || (this.obj.Trigger.relatedTemplates && this.obj.Trigger.relatedTemplates.length !== 0 && this.obj.Trigger.relatedTemplates[0].type === "IVR")) {
        heading += ' IVR '
      } else {
        heading += ' SMS '
      }
      heading += ' Trigger - ' + this.obj.DeploymentCode
      return heading
    },
    submit () {
      let submitFunction
      if (this.obj.Type === 'Clone') {
        submitFunction = this.cloneTrigger
      } else {
        submitFunction = this.updateTrigger
      }
      return submitFunction
    },
    async updateTrigger (item) {
      await this.updateTriggerPostCall({ data: item, path: "/v1/trigger" })
      this.$router.push({ name: 'triggerManagement', query: { platform: this.$route.query.platform } })
    },
    async cloneTrigger (item) {
      const inputCSV = [
        ["hierarchyId", "triggerId", "defaultTemplateId", "templateIds", "cronTime", "eventName", "functionName", "mandatory", "entityTimeRelated", "isActive", "isNewTemplate", "content", "languageId", "typeId", "parameters", "unicode"],
        [item.hierarchyId, item.triggerId, item.defaultTemplateId, item.templateIds, item.cronTime, item.eventName, item.functionName, item.mandatory, item.entityTimeRelated, item.isActive, false, null, null, null, null, null]
      ]
      var data = formatTriggerdata(inputCSV)
      await this.cloneTriggerPostCall({ data: JSON.stringify(data), path: '/v1/trigger' })
      this.$router.push({ name: 'triggerManagement', query: { platform: this.$route.query.platform } })
    }
  }
}
</script>
