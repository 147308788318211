<template>
<v-overlay>
  <transition name="modal-fade">
    <v-overlay>
    <div class="modal-backdrop">
      <div class="modal text-ibm-plex-sans" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
        <header class="modal-header" id="modalTitle" v-if="confirmationDialogText.title">
          <slot name="header">
            {{ confirmationDialogText.title }}
            <button type="button" class="btn-close" @click="close" aria-label="Close modal"></button>
          </slot>
        </header>
        <section class="modal-body" id="modalDescription">
          {{ confirmationDialogText.dialogtext }}
          <slot name="body"> </slot>
        </section>
        <footer class="modal-footer">
          <slot name="footer">
            {{ footerText }}
            <button type="button" class="btn-primary ml-8 white-button" id="ghostButton" @click="close" aria-label="Close modal">
              {{ confirmationDialogText.dialogCancelText }}
            </button>
            <button type="button" class="btn-primary ml-8 white-text" @click="onSubmit()" aria-label="Close modal">
              {{ confirmationDialogText.dialogSubmitText }}
            </button>
          </slot>
        </footer>
      </div>
    </div>
    </v-overlay>
  </transition>
</v-overlay>
</template>
<script>

export default {

  name: 'Modal',
  props: {
    item: {
      type: Object
    },
    submitFunction: {
      type: Function
    },
    footerText: {
      type: String
    },
    confirmationDialogText: {
      type: Object
    }
  },
  data () {
    return {
      showConfirmationDialog: {
        default: false
      }
    }
  },
  methods: {
    onSubmit () {
      this.showConfirmationDialog = false
      this.submitFunction(this.item)
      this.$emit('update:showConfirmationDialog', false)
    },
    close () {
      this.showConfirmationDialog = false
      this.$emit('update:showConfirmationDialog', false)
    }
  }
}
</script>
<style lang="scss">
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    /* box-shadow: 2px 2px 20px 1px; */
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    background: #FCFCFC;
    border-radius: 16px;
    padding: 32px;
    max-width: 519px;
  }

  .modal-header,
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }

  .modal-header {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    /* Dark/Title */
    color: #1E234C;
  }

  .modal-footer {
    margin-top: 48px;
    justify-content: flex-end;
  }

  .modal-body {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.0125em;
    text-align: start;
    /* Body Text */
    color: #464A6B;
  }

  .btn-close {
    border: none;
    cursor: pointer;
    font-weight: bold;
    height: 18px;
    width: 18px;
    background-color: transparent;
    background-image: url("../../assets/VectorCross.svg");
    background-repeat: no-repeat;
}

  .btn-primary {
    background: #1155CC;
    border: none;
    align-items: center;
    padding: 19px 24px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .ml-8 {
    margin-left: 8px;
  }

  .white-button {
    color: #1E234C;
    background-color: #FCFCFC;
  }

  #ghostButton {
    box-shadow: none;
  }

  .white-text {
    color: white !important;
  }
</style>
