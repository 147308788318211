<template>
  <div class="">
    <div>
      <label :class=getLabelClass()>
        <input
          :name=name
          :class=getInputClass()
          :placeholder=placeholderText
          :disabled="isDisabled"
          readonly
          :value="displayValue"
          v-on:click="toggleDropdown"
        >
      </label>
      <div class="dropdown">
        <div :class=getDropdownClass()>
          <div v-if="allOptions.length > 10">
            <img role="img" src="../../assets/search.svg" />
            <input
              :name=name
              class="search-input"
              placeholderText="Search Options"
              autocomplete="off"
              v-model="localSearchValue">
          </div>
          <ul>
            <li
              v-for="option in filteredOptions"
              class="dropdown-item"
              v-on:click="handleSelect"
              :data-key="option.Key"
              :data-value="option.Value"
              v-bind:key="option.Key"
            >
              <input
                v-if="isMultiSelect"
                type="checkbox"
                :checked="isOptionSelected(option.Key)"
              />
              <span class="value">
                {{ option.Value }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="hasError" class="error-container">
        <p class="error-message">
          {{ errorMessage }}
        </p>
      </div>
      <div v-if="hasInfo && !hasError" class="text-info">{{ infoMessage }}</div>
    </div>
  </div>
</template>

<script>

export default {
  data (self) {
    return {
      localValue: ((self.value) ? self.value : (this.isMultiSelect ? [] : {})),
      dropdownOpen: false,
      localSearchValue: '',
      filteredOptions: this.allOptions,
      maxLength: 30
    }
  },
  name: 'Select',
  mounted () {
    this.localValue = ((this.value) ? this.value : (this.isMultiSelect ? [] : {}))
    const self = this
    document.addEventListener('click', function (event) {
      event.stopPropagation()
      if (self.dropdownOpen && (self.name !== event.target.name)) {
        self.closeDropdown()
      }
    })
  },
  props: {
    value: {
      type: [Object, Array, String],
      default: null
    },
    name: {
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    allOptions: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholderText: {
      type: String,
      default: 'Default option'
    },
    isMultiSelect: {
      type: Boolean,
      default: false
    },
    hasInfo: {
      type: Boolean,
      default: false
    },
    infoMessage: {
      type: String,
      default: null
    }
  },
  watch: {
    localSearchValue () {
      this.filteredOptions = this.allOptions
        .filter(o => o.Value.toLowerCase().includes(this.localSearchValue.toLowerCase()))
    },
    localValue (value) {
      this.$emit('input', value)
    }
  },
  computed: {
    displayValue () {
      let value = !this.value ? '' : this.value

      if (!this.localValue) {
        return value
      }

      if (this.isMultiSelect) {
        let currentLength = 0
        let additionalItems = 0
        const selectedItems = []

        for (const option of this.localValue) {
          const filteredOptions = this.allOptions.filter(o => o.Key === option.Key)
          if (filteredOptions.length === 0) {
            continue
          }
          const optionText = filteredOptions[0].Value
          if ((currentLength + optionText.length) < this.maxLength) {
            currentLength += optionText.length
            selectedItems.push(optionText)
          } else {
            additionalItems++
          }
        }

        value = selectedItems.join(',')
        if (additionalItems > 0) {
          value += ' + ' + additionalItems + ' more'
        }
      } else {
        const optionText = this.localValue.Value ? this.localValue.Value : ''
        if (optionText.length > this.maxLength) {
          value = optionText.substring(0, this.maxLength) + '...'
        } else {
          value = optionText
        }
      }
      return value
    }
  },
  methods: {
    getInputClass () {
      return {
        select: true,
        error: this.hasError
      }
    },

    getLabelClass () {
      return {
        label: true,
        error: this.hasError
      }
    },

    getDropdownClass () {
      return {
        'dropdown-menu': true,
        show: this.dropdownOpen
      }
    },

    toggleDropdown () {
      if (this.dropdownOpen) {
        this.closeDropdown()
      } else {
        this.openDropdown()
      }
    },

    openDropdown () {
      this.dropdownOpen = true
    },

    closeDropdown () {
      this.dropdownOpen = false
    },

    handleSelect (event) {
      event.stopPropagation()
      const li = event.target.closest('li')
      const selectedValue = {
        Key: li.dataset.key,
        Value: li.dataset.value
      }
      if (this.isMultiSelect) {
        if (this.localValue && this.localValue.map((item) => item.Key).includes(selectedValue.Key)) {
          this.localValue.splice(this.localValue.map((item) => item.Key).indexOf(selectedValue.Key), 1)
        } else {
          this.localValue.push(selectedValue)
        }
      } else {
        this.localValue = selectedValue
        this.closeDropdown()
      }
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
    },

    isOptionSelected (value) {
      if (!this.localValue) {
        return false
      }
      return this.localValue.map((item) => item.Key).includes(value)
    }
  }
}
</script>

<style lang="scss" scoped>

.select {
  padding: 11px 16px;
  border: none;
  line-height: 26px;
  border-radius: 8px;
  color: #1E234C;
  background-size: 16px 16px;
  background: #FFFFFF url("../../assets/select.svg") no-repeat 285px 1rem;
  width: 100%;
  cursor: default;
  // box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #9CA5C2;
  /*z-index: 1001;*/
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: bold;
}

.select::placeholder {
  color: #A0A3BD;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.select:hover {
  border : none;
  border: 1px solid #4200FF;
}

.select:disabled {
  background: #EFF0F6;
  border: none;
}

.select:focus-visible {
  outline: none;
}

.error-container{
  margin-top: 2px;
}

.error-message{
  margin: 0px;
  color: #EF485C;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.text-info{
  margin-top: 2px;
  color: #0070D9;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.select.error{
  border: 1px solid #EF485C !important;
  color: #EF485C;
}

.label {
  display: flex;
  flex-direction: column-reverse;
  text-align: initial;
  width: 100%;
}

.label span {
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #1E234C;
  transform-origin: top left;
  font-family: 'IBM Plex Sans', sans-serif;
  height: 22px;
}

.label.error span {
  color: #EF485C;
}

.dropdown {
  position: relative;
}

.dropdown img {
  position: absolute;
  left: 67.5%;
  right: 31.36%;
  top: 42.73%;
  bottom: 56.12%;

  /* Placeholder */

  background: #A0A3BD;
}

.dropdown-menu {
  // border: 1px solid #9CA5C2;
  display: none;
  position: absolute;
  text-align: left;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  float: left;
  z-index: 1000;
  min-width: 100%;
  margin: 0;
  // padding-inline: 0;
  /*max-height: 240px;*/
  /*overflow-y: scroll;*/
  /*padding-top: 48px;*/
  margin-top: 2px;
  border-radius: 8px;
}

.dropdown-menu ul {
  list-style: none;
  margin-left: 0;
  padding-inline-start: 0;
  max-height: 176px;
  overflow-y: scroll;
  margin-right: 15px;
  /*margin-top: 7px;*/
  /*margin-bottom: 0;*/
}

.dropdown-menu li.hidden {
  min-height: 30px;
}

.dropdown-menu li {
  padding-left: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 26px;
  font-size: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  color: #6E7191;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #F7F7FC;
}

.dropdown-menu li span.value {
  margin-left: 8px;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #FFFFFF;
}

::-webkit-scrollbar-track {
  background: #FFFFFF;
}

::-webkit-scrollbar-thumb {
  background: #D6D8E7;
}

.show {
  display: block;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  appearance: none;
  border: 2px solid #6E7191;
  border-radius: 2px;
  margin: 1px 0 0;
  position: relative;
  top: 4px;
}

input[type="checkbox"]:checked {
  border-color: #4200FF;
  background: #4200FF url('../../assets/check.svg') no-repeat;
}

.search-input {
  border: none;
  margin: 10px 10px 0px 10px;
  background: #F7F7FC;
  border-radius: 8px;
  height: 40px;
  padding-left: 36px;
  min-width: 240px;
}
</style>
