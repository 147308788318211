<template>
<div>
  <div class="flex padding">
    <div class="flex switch-ellipse" v-bind:class=getEllipseClass() v-on:click="toggle">
      <div class="switch-circle" v-bind:class=getCircleClass()>
      </div>
    </div>
  </div>
  <v-overlay v-if="this.openDialog">
    <Modal :showConfirmationDialog.sync="openDialog" :item="submitObject" :submitFunction="toggleSubmit" :confirmationDialogText="confirmationDialogText"/>
  </v-overlay>
</div>
</template>

<script>
import Modal from './Modal.vue'

export default {
  name: 'Toggle',
  components: { Modal },
  props: {
    name: {
      type: String,
      default: 'Toggle'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    toggleSubmit: {
      type: Function,
      default: () => { return -1 }
    },
    submitObject: {
      type: Object,
      default: () => {}
    },
    useSubmitMethod: {
      type: Boolean,
      default: false
    },
    openDialog: {
      type: Boolean,
      default: false
    },
    confirmationDialogText: {
      type: Object,
      default: () => { }
    }
  },
  methods: {
    toggle () {
      if (this.useSubmitMethod) {
        this.openDialog = true
      } else {
        if (!this.disabled) {
          this.$emit('update:input', !this.value)
        }
      }
    },
    getEllipseClass () {
      return {
        'on-ellipse': this.value && !this.disabled,
        'off-ellipse': !this.value,
        'enabled-ellipse': !this.disabled,
        'disabled-on-ellipse': this.value && this.disabled
      }
    },
    getCircleClass () {
      return {
        'on-circle': this.value && !this.disabled,
        'off-circle': !this.value || this.disabled,
        'enabled-circle': !this.disabled
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.padding {
  padding: 10px;
}
.flex{
  display: flex;
  justify-content: left;
}

.text-ibm-plex-sans-14{
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
}
.switch-ellipse {
  height: 22px;
  width: 40px;
  align-items: center;
  border-radius: 100px;
  transition: all 0.5s;
  cursor:pointer;
}
.switch-circle {
  width: 22px;
  height: 22px;
  border-radius: 11px;
}
.on-ellipse {
  background: rgba(13, 193, 171, 0.15); // Can be changed with $ variables once decided on
  justify-content: flex-end;
}
.off-ellipse {
  background: rgba(160, 163, 189, 0.15);
  justify-content: flex-start;
}
.on-circle {
  background: #0DC1AB;
}
.off-circle {
  background: #A0A3BD;
}
.enabled-ellipse {
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.disabled-on-ellipse {
  background: rgba(160, 163, 189, 0.15);
  justify-content: flex-end;
  cursor:default;
}
.enabled-circle {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.off-ellipse:not(.enabled-ellipse) {
  cursor:default;
}
</style>
